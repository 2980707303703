import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

export const LocationDropDownContainer = styled.div``;

export const LocationDropDownFilter = styled(Box)({
    display:  'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginLeft: '10px'
});
