import { Autocomplete, Link, MenuItem, TextField, Typography } from '@mui/material';
import { Delete, Download, Edit, ListAlt } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as type from './Sku.type';
import * as view from './Sku.view';
import ApiSku from '../../api/ApiSku';
import ApiSkuType from '../../api/ApiSkuType';
import { Success } from '../Popup/Popup';
import { Skus } from '../../states/component/Sku';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ColumnsTable, ModalType } from '../../types/common';
import { SkuTypeModel } from '../../models/ApiSkuType.type';
import { ISignUpForm } from './Sku.type';
import { useStyles } from './Sku.style';
import { TextEllipsis } from '../../styled/global.style';
import { ModelSku } from '../../models/ApiSku.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { OrderAlphabetically, removeSpaces } from '../../helpers/converter';
import { verifyPermission } from '../../helpers/Auth';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import ImagePopup from '../ImagePopup/ImagePopup';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import SkuPrices from '../SkuPrices/SkuPrices';
// import module

const api = new ApiSku();
const apiSkuType = new ApiSkuType();

const Sku: React.FC<type.SkuProps> = () => {
    const Trans = useRecoilValue(Translator);
    const setSkus = useSetRecoilState<ModelSku[]>(Skus);
    const [open, setOpen] = useState<boolean>(false);
    const [skuId, setSkuId] = useState<number | null>(null);
    const [sku, setSku] = useState<ModelSku | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [skuType, setSkuType] = useState<SkuTypeModel[]>([]);
    const [skuData, setSkuData] = useState<type.SkuI>();
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(true);
    const getDateMask = useRecoilValue(GetDateMask);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const classes = useStyles();
    const [modalSkuPrices, setModalSkuPrices] = useState<boolean>(false);

    const [valuesInitForm, setvaluesInitForm] = useState<ISignUpForm>({
        name: '',
        skuCode: '',
        customerLabel: '',
        skuCodeCustomer: '',
        itemType: '',
        itemName: '',
        s3Key: '',
        existItems: false,
        archived: false,
        withSerialNumber: false,
        lengthMm: 0,
        heightMm: 0,
        widthMm: 0,
        weightKg: 0,
        volume: 0
    });

    const [dataModal, setdataModal] = useState<ModalType>({
        isAdd: true,
        title: Trans('messages.t.add_new') + ' SKU',
        id: null
    });

    const showbtnAdd: string | undefined = verifyPermission(['add_sku']);
    const showbtnEdit: string | undefined = verifyPermission(['edit_sku']);
    const showbtnRemove: string | undefined = verifyPermission(['remove_sku']);

    const showActionBottoms = showbtnEdit === undefined && showbtnRemove === undefined ? false : true;

    const getAllSku = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setSkus([]);
            const data = await api.getAll(page, limit, order, filter);

            setSkus(data.items);
            setPageCount(data.paginator.pageCount);
            setTotal(data.paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setSkus]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let items: ModelSku[] = [];

        for (let i = 1; i <= pages; i++) {
            const data = await api.getAll(i, 200, order, filter);
            pages = data.paginator.pageCount;
            items = items.concat(data.items);
        }

        return items;
    };

    const openPrices = async (sku) => {
        setSku(sku);
        setModalSkuPrices(true);
    };

    const openModal = async (ActionType = 'create', id: number | null = null): Promise<void> => {
        setLoadingForm(true);
        ActionType !== 'edit' && setOpen(true);

        setdataModal({
            isAdd: ActionType !== 'edit',
            title:
                ActionType !== 'edit'
                    ? Trans('messages.t.add_new') + ' SKU'
                    : Trans('messages.t.edit') + ' SKU' + ' - #' + id,
            id: ActionType !== 'edit' ? null : id
        });
        await getSelect();
        setLoadingForm(false);
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            name: '',
            customerLabel: '',
            skuCodeCustomer: '',
            skuCode: '',
            itemType: '',
            s3Key: '',
            existItems: false,
            archived: false,
            withSerialNumber: false,
            itemName: '',
            lengthMm: 0,
            heightMm: 0,
            widthMm: 0,
            weightKg: 0,
            volume: 0
        });
        setOpen(false);
    };

    const getSelect = async () => {
        const skuType: SkuTypeModel[] = await apiSkuType.getSkuTypeCodeBook();
        setSkuType(OrderAlphabetically<SkuTypeModel>(skuType, 'type'));
    };

    const handleSubmit = async (
        e: ISignUpForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting
    ): Promise<void> => {
        try {
            e.skuType = e.itemType;
            delete e.itemType;
            delete e.itemName;

            e.skuCode = removeSpaces(e.skuCode);
            e.name = removeSpaces(e.name);

            const { data }: ModelSku = dataModal.isAdd ? await api.create(e) : await api.patch(dataModal.id ?? 0, e);

            if (!data) {
                throw data;
            }
            setRefresh(true);
            resetForm({});

            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.sku_created_successfully')} - ${data.sku.name}`
                    : `${Trans('messages.t.sku_successfully_updated')} - ${data.sku.name}`
            });

            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editSku = async (row: ModelSku): Promise<void> => {
        setLoadingForm(true);
        setOpen(true);
        const { sku }: ModelSku = await api.getById(row.id);

        if (sku) {
            setvaluesInitForm({
                name: sku.name,
                customerLabel: sku.customerLabel ? sku.customerLabel : '',
                skuCodeCustomer: sku.skuCodeCustomer ? sku.skuCodeCustomer : '',
                skuCode: sku.skuCode,
                itemType: sku.skuType ? sku.skuType.id : '',
                itemName: sku.skuType && sku.skuType.type,
                s3Key: sku.s3Key,
                existItems: sku.skuType && sku.existItems ? sku.existItems : false,
                archived: sku.archived ? sku.archived : false,
                withSerialNumber: sku.withSerialNumber ? sku.withSerialNumber : false,
                lengthMm: sku.lengthMm || 0,
                heightMm: sku.heightMm || 0,
                widthMm: sku.widthMm || 0,
                weightKg: sku.weightKg || 0,
                volume: (sku.lengthMm || 0) * (sku.heightMm || 0) * (sku.widthMm || 0)
            });
            setSkuId(sku.id);
            setSkuData(sku);
        }
        openModal('edit', row.id);
    };

    const confirmDeleteRow = (row: ModelSku): void =>
        popUpConfirm('SKU' + ` ${row.name}`, Trans('messages.p.are_you_sure_to_delete'), deleteRow, row, {
            yes: Trans('messages.t.yes'),
            no: Trans('messages.t.no')
        });

    const deleteRow = async (onClose, row: ModelSku): Promise<void> => {
        try {
            const sku: ModelSku = await api.remove(row.id);

            if (!sku) {
                throw sku;
            }
            setRefresh(true);
            onClose();
            Success({ text: `${Trans('messages.t.sku_successfully_removed')} - ${row.name}` });
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const typeSelectFilter = (column, setFilter): JSX.Element => {
        return (
            <Autocomplete
                getOptionLabel={(option) => Trans(`messages.t.${option.type}`)}
                id='skuTypeFilter'
                onChange={(_, value) => {
                    const values = !value
                        ? undefined
                        : {
                              name: column.Header,
                              humanValue: Trans(`messages.t.${value.type}`),
                              value: value.type
                          };
                    setFilter(column.id, values);
                }}
                defaultValue={skuType.find((type) => `${type.type}` === `${column.filterValue?.value}`)}
                options={skuType}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={column.Header}
                        value={column.filterValue?.value || null}
                        InputLabelProps={{
                            shrink: true
                        }}
                        // className={classes.inputFilterColumn}
                        size='small'
                        variant='outlined'
                        fullWidth
                    />
                )}
            />
        );
    };

    const ActionsButtons = (original: ModelSku): JSX.Element => {
        return (
            <>
                {showbtnEdit && (
                    <Typography className='dataTableActionsBtn' onClick={() => editSku(original)}>
                        <Edit />
                        <span>{Trans('messages.t.edit')}</span>
                    </Typography>
                )}

                {original.skuType && original.skuType.type !== 'box' && (
                    <Typography className='dataTableActionsBtn' onClick={() => openPrices(original)}>
                        <ListAlt />
                        <span>{Trans('messages.t.prices')}</span>
                    </Typography>
                )}

                {original.s3Key && (
                    <Typography className='dataTableActionsBtn'>
                        <Download />
                        <span>
                            <Link
                                href={api.getSpecificationUrl(original)}
                                target='_blank'
                                rel='noreferrer'
                                variant='inherit'
                                underline='none'
                            >
                                {Trans('messages.t.product_specification')}
                            </Link>
                        </span>
                    </Typography>
                )}

                {showbtnRemove && (
                    <Typography
                        className={`dataTableActionsBtn ${original.existItems ? 'disabled' : 'danger'}  `}
                        onClick={() => (original.existItems ? '' : confirmDeleteRow(original))}
                    >
                        <Delete />
                        <span>{Trans('messages.t.remove')}</span>
                    </Typography>
                )}
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: Trans('messages.t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) => (row.original?.itemImageS3Key ? api.getImageUrl(row.original) : ''),
            Cell: ({ row }: CellProps) => (
                <ImagePopup imageUrl={api.getImageUrl(row.original)} thumbnailUrl={api.getImageUrl(row.original)} />
            ),
            minWidth: 85,
            maxWidth: 90
        },
        {
            Header: Trans('messages.t.code'),
            accessor: 'skuCode',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 200,
            Cell: ({ row }: CellProps) => <TextEllipsis title={row.original.name}> {row.original.name} </TextEllipsis>
        },
        {
            Header: Trans('messages.t.sku_visible_for_customer'),
            accessor: 'skuCodeCustomer',
            disableGroupBy: true,
            disableSortBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 200,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.skuCodeCustomer}>
                    {' '}
                    {row.original?.skuCodeCustomer || '---'}{' '}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.name_visible_for_customer'),
            accessor: 'customerLabel',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 200,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.customerLabel}> {row.original?.customerLabel || '---'} </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.type'),
            accessor: 'skuType',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Export: ({ row }: CellProps) => Trans(`messages.t.${row.original.skuType.type}`),
            Cell: ({ row }: CellProps) => Trans(`messages.t.${row.original.skuType.type}`),
            Filter: ({ column, setFilter }: CellProps) => {
                return typeSelectFilter(column, setFilter);
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo'
        },
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.archived'),
            accessor: 'archivedStatus',
            disableGroupBy: true,
            disableSortBy: true,
            aggregate: 'count',
            canFilters: true,
            Export: ({ row }: CellProps) => (row.original.archived ? Trans('messages.t.yes') : Trans('messages.t.no')),
            Cell: ({ row }: CellProps) => (row.original.archived ? Trans('messages.t.yes') : Trans('messages.t.no')),
            Filter: ({ column, setFilter }: CellProps) => {
                const filters: string[] = [
                    Trans('messages.t.not_defined'),
                    Trans('messages.t.yes'),
                    Trans('messages.t.no')
                ];

                return (
                    <TextField
                        className={classes.inputFilterColumn}
                        select
                        variant='outlined'
                        label={column.Header}
                        value={column.filterValue?.value || ''}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            const value = !e.target.value
                                ? undefined
                                : {
                                    name: column.Header,
                                    value: e.target.value,
                                    humanValue: filters[Number(e.target.value)]
                                  };
                            setFilter(column.id, value);
                        }}
                    >
                        <MenuItem value='0'>{Trans('messages.t.not_defined')}</MenuItem>
                        <MenuItem value='1'>{Trans('messages.t.yes')}</MenuItem>
                        <MenuItem value='2'>{Trans('messages.t.no')}</MenuItem>
                    </TextField>
                );
            }
        },
        {
            Header: Trans('messages.t.price_in_usd'),
            accessor: 'shippingPriceUsd',
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: true,
            disableFilters: true,
            Export: ({ row }: CellProps) =>
                row.original.activeSkuPrice ? row.original.activeSkuPrice.shippingPriceUsd + '$' : '---',
            Cell: ({ row }: CellProps) =>
                row.original.activeSkuPrice ? row.original.activeSkuPrice.shippingPriceUsd + '$' : '---'
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) =>
                showActionBottoms && <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
        }
    ];

    useEffect(() => {
        getSelect();
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <>
            <SkuPrices
                Sku={sku}
                modalSkuPrices={modalSkuPrices}
                setModalSkuPrices={setModalSkuPrices}
                setSku={setSku}
                typeModal='drawer'
                showAction={true}
            />
            <view.SkuContent
                data-testid={'Sku-testid'}
                openModal={openModal}
                open={open}
                isLoading={loading}
                closeModal={handleClose}
                handleSubmit={handleSubmit}
                columns={columns}
                valuesInitForm={valuesInitForm}
                dataModal={dataModal}
                fullScreenModal={getFullScreenModal()}
                showBtnAdd={showbtnAdd}
                skuType={skuType}
                sku={skuData}
                fetchData={getAllSku}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                loadingForm={loadingForm}
                imageUrl={skuData?.id ? api.getImageUrl(skuData) : ''}
                specificationUrl={skuData?.s3Key ? api.getSpecificationUrl(skuData) : ''}
            />
        </>
    );
};

export default Sku;
