import { IconButton } from '@mui/material';
import { ArrowForwardOutlined, Delete, Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import * as type from './EditShippingOrder.type';
import * as view from './EditShippingOrder.view';
import ApiShippingOrders from '../../../api/ApiShippingOrders';
import ApiItems from '../../../api/ApiItems';
import ApiCustomer from '../../../api/ApiCustomer';
import * as ApiItemsModel from '../../../models/ApiItems.type';
import Wrapper from '../../../helpers/wrapper';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Translator from '../../../states/global/Translator';
import * as UserState from '../../../states/global/User';
import BoxItems from '../../BoxItems/BoxItems';
import { Success, Warning } from '../../Popup';
import { ColumnsTable, ModelSkuCodeBook } from '../../../types/common';
import * as SkuModel from '../../../models/ApiSku.type';
import { ItemsAvailablesFilter } from '../../CreateOrder/CreateOrder.type';
import { IStockLocation } from '../../../models/ApiStockLocation.type';
import { FullDataUser } from '../../User/User.type';
import { IEditOrderForm, IShippingOrder, ItemsResendOrder, ItrackCustomer } from './EditShippingOrder.type';
import { FromUserTimezoneToTimezone, FromUTCToTimezone, GetDateMask } from '../../../helpers/Converters';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { TextEllipsis, TitleItemWithIcon } from '../../../styled/global.style';
import { OptionType } from '../../Device/Device.type';
import { ItemsType } from '../../Box/Box.type';
import { Model } from '../../../models/ApiShippingOrders.type';
import { ModelCustomerItrack } from '../../../models/ApiCustomer.type';
import * as ShippingOrderState from '../../../states/component/ShippingOrder';
// import module

const api = new ApiShippingOrders();
const apiItem = new ApiItems();
const apiCustomers = new ApiCustomer();

const EditShippingOrder: React.FC<type.EditShippingOrderProps> = (props): JSX.Element => {
    const [stockLocationTo, setStockLocationTo] = useState<IStockLocation | null>(null);
    const [shippingOrder, setShippingOrder] = useState<IShippingOrder | null>(null);
    const userState = useRecoilValue<FullDataUser | null>(UserState.User);
    const [itemsFrom, setItemsFrom] = useState<ApiItemsModel.AvailableModel[]>([]);
    const [itemsFromValue, setItemsFromValue] = useState<{ id: number; item: number; value: null | number }[]>([]);
    const [itemsToSend, setItemsToSend] = useState<any[]>([]);
    const [savedItems, setSavedItems] = useState<any[]>([]);
    const [itemsToSendDeleted, setItemsToSendDeleted] = useState<any[]>([]);
    const Trans = useRecoilValue(Translator);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [resetLocationTo, setResetLocationTo] = useState<boolean>(false);
    const [boxId, setBoxId] = useState<number>();
    const [SKUs, setSKUs] = useState<ModelSkuCodeBook[]>([]);
    const notData = '---';
    const [modalItems, setModalItems] = useState<boolean>(false);
    const [shippedAtExpired, setShippedAtExpired] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string>('');
    const fromUserTimezoneToTimezone = useRecoilValue(FromUserTimezoneToTimezone);
    const getDateMask = useRecoilValue(GetDateMask);
    const [usedLocations, setUsedLocations] = useState<any[]>([]);
    const [customers, setCustomers] = useState<ModelCustomerItrack[]>([]);
    const [locationFilter, setLocationFilter] = useState<ItemsAvailablesFilter>({
        location: '',
        type: '',
        skuId: '',
        serialNumber: ''
    });
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const setStatusHistory = useSetRecoilState(ShippingOrderState.StatusHistory);
    const [valuesInitForm, setvaluesInitForm] = useState<IEditOrderForm>({
        stockLocationFrom: '',
        stockLocationTo: '',
        type: '',
        saleOrderId: '',
        itrackCustomerId: {} as ItrackCustomer,
        shipperSelect: '',
        shippedAt: '',
        trackingId: '',
        trackingEnabled: false,
        invoiceIncoerms: '',
        invoiceExw: ''
    });
    const [disabledCustomer, setDisabledCustomer] = useState<boolean>(true);

    const Formschema = Yup.object().shape({
        itrackCustomerId: Yup.array().nullable(),
        shipperSelect:
            props.resendOrder || shippingOrder?.shippingOrderStatus?.id === 7
                ? Yup.object()
                      .shape({
                          id: shippedAtExpired ? Yup.string() : Yup.string(),
                          name: shippedAtExpired ? Yup.string() : Yup.string()
                      })
                      .nullable()
                : Yup.object().shape({
                      id: shippedAtExpired
                          ? Yup.string().required(Trans('messages.p.this_field_is_required'))
                          : Yup.string(),
                      name: shippedAtExpired
                          ? Yup.string().required(Trans('messages.p.this_field_is_required'))
                          : Yup.string()
                  }),
        stockLocationFrom: Yup.object().shape({
            id: Yup.string().required(Trans('messages.p.this_field_is_required')),
            name: Yup.string().required(Trans('messages.p.this_field_is_required'))
        }),
        stockLocationTo: Yup.object().shape({
            id: Yup.string().required(Trans('messages.p.this_field_is_required')),
            name: Yup.string().required(Trans('messages.p.this_field_is_required'))
        }),
        type: Yup.string(),
        shippedAt: Yup.string(),
        trackingId:
            shippedAtExpired && !props.resendOrder
                ? Yup.string().required(Trans('messages.p.this_field_is_required'))
                : Yup.string(),
        saleOrderId: Yup.string().required(Trans('messages.p.this_field_is_required')),
        invoiceIncoerms: Yup.string().max(100),
        invoiceExw: Yup.string().max(100),
        shipmentAcceptanceEmail: Yup.string()
            .test('emailsValidation', Trans('messages.p.please_enter_valid_email'), function (value) {
                if (!value) {
                    return true;
                }

                const emailSchema = Yup.array().of(Yup.string().email());
                const emails = value.split(';').map((email) => email.trim());

                return emailSchema.isValidSync(emails);
            })
            .nullable(),
        trackingEnabled: Yup.boolean()
    });

    const getItems = async (id) => {
        setBoxId(id);
        setModalItems(true);
    };

    const columnsChangeStatus = [
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            width: 132,
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false)
        },
        {
            Header: Trans('messages.t.status'),
            accessor: 'shippingOrderStatus.status',
            disableGroupBy: true,
            aggregate: 'count',
            width: 80,
            canFilters: true,
            Cell: ({ row }: CellProps) =>
                Trans('messages.t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase())
        },
        {
            Header: Trans('messages.t.comment'),
            accessor: 'comment',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => row.original.comment
        }
    ];

    const getResourcesForEdit = useCallback(async () => {
        if (!stockLocationTo) {
            try {
                const { data }: any = await api.getGeneral(
                    `stock/shipping-order-status-history/shipping-order/${props.shippingOrderId}`
                );

                setStatusHistory(data || []);
                const {
                    data: { shippingOrder }
                }: any = await api.getGeneral(`stock/shipping-order/${props.shippingOrderId}`);

                if (shippingOrder) {
                    const locatonTo = shippingOrder.locationTo;
                    setShippingOrder(shippingOrder);
                    setStockLocationTo(shippingOrder.locationTo);
                    setLocationFilter({ ...locationFilter, location: shippingOrder.locationFrom.id });
                    const newItemsToSend: any = shippingOrder.shippingOrderItem.length
                        ? shippingOrder.shippingOrderItem.map((addI) => {
                              addI.quantityTem = addI.quantity;
                              addI.quantityOrigin = addI.quantity;
                              return addI;
                          })
                        : [];
                    const newDataItems: any = newItemsToSend.length ? formatDataItems(newItemsToSend, false) : [];

                    const newDataValue = shippingOrder.shippingOrderItem.length
                        ? shippingOrder.shippingOrderItem.map((ItemFrom) => ({
                              id: ItemFrom.id,
                              item: ItemFrom.item.id,
                              value: null
                          }))
                        : [];
                    setItemsFromValue(newDataValue);
                    if (shippingOrder && shippingOrder.shippedAt) {
                        const format = 'YYYY-MM-DD HH:mm';
                        const shippedAtDate: moment.Moment = momentTimezone(shippingOrder.shippedAt, format);
                        const currentDateTime: string = momentTimezone
                            .tz(userState ? userState?.userSetting.timezone.timezoneName : 'Europe/Bratislava')
                            .format(format);
                        setShippedAtExpired(moment(currentDateTime).isAfter(shippedAtDate, 'minute'));
                    }

                    !itemsToSend.length && setItemsToSend(newDataItems);
                    !savedItems.length && setSavedItems(newDataItems);

                    let shippedAt: moment.Moment | string = '';
                    if (shippingOrder && shippingOrder.shippedAt) {
                        shippedAt = fromUserTimezoneToTimezone(shippingOrder.shippedAt);
                    }

                    if (shippingOrder.locationFrom) {
                        setvaluesInitForm({
                            stockLocationFrom: shippingOrder.locationFrom,
                            stockLocationTo: shippingOrder.locationTo,
                            shipperSelect: shippingOrder.shipper ? shippingOrder.shipper : '',
                            itrackCustomerId: shippingOrder.itrackCustomerId ? shippingOrder.itrackCustomerId : {},
                            type: '',
                            saleOrderId: shippingOrder.saleOrderId,
                            shipmentAcceptanceEmail: shippingOrder.shipmentAcceptanceEmail,
                            shippedAt: shippingOrder.shippedAt ? shippedAt : '',
                            trackingId: shippingOrder.trackingId ? shippingOrder.trackingId : '',
                            trackingEnabled: shippingOrder.trackingEnabled,
                            invoiceExw: shippingOrder.invoiceExw || '',
                            invoiceIncoerms: shippingOrder.invoiceIncoerms || ''
                        });
                    }
                    if (!props.resendOrder) {
                        getCustomers(shippingOrder.locationTo.id);
                    }
                    if (props.resendOrder && locatonTo) {
                        setvaluesInitForm({
                            stockLocationFrom: locatonTo,
                            stockLocationTo: '',
                            shipperSelect: '',
                            itrackCustomerId: {} as ItrackCustomer,
                            type: '',
                            saleOrderId: '',
                            shipmentAcceptanceEmail: '',
                            shippedAt: '',
                            trackingId: '',
                            trackingEnabled: false,
                            invoiceExw: '',
                            invoiceIncoerms: ''
                        });
                    }
                }
                setLoading(false);
            } catch (error) {
                console.warn('Get List Shipping Order ', error);
            }
        }
    }, [userState, props.shippingOrderId, itemsToSend, stockLocationTo]);

    const handleClose = (): void => {
        setItemsFrom([]);
        setItemsToSend([]);
        setLocationFilter({ location: '', type: '', skuId: '', serialNumber: '' });
        props.closeModal();
    };

    const getItemsAvailables = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoadingItems(true);
            let paramTypeFilter = extraFiltering.type;
            let paramTypeTablet = '';
            let filterBySerialNumber = '';
            let paramTypeSKU = '';

            if (extraFiltering.type && extraFiltering.type !== 'all') {
                paramTypeFilter = '&type=' + extraFiltering.type;
                paramTypeSKU = `/${extraFiltering.type}`;
                if (extraFiltering.skuId) {
                    paramTypeTablet = '&skuId=' + extraFiltering.skuId;
                } else {
                    setResetFilter(true);
                }
            } else {
                setResetFilter(true);
            }

            setvaluesInitForm({
                stockLocationFrom: '',
                stockLocationTo: '',
                itrackCustomerId: {} as ItrackCustomer,
                shipperSelect: '',
                type: extraFiltering.type,
                saleOrderId: '',
                shipmentAcceptanceEmail: '',
                shippedAt: '',
                trackingId: '',
                trackingEnabled: false,
                invoiceIncoerms: '',
                invoiceExw: ''
            });

            if (extraFiltering.serialNumber) {
                filterBySerialNumber = `&serialNumber=${extraFiltering.serialNumber}`;
            }

            if (extraFiltering.location) {
                setItemsFrom([]);
                setItemsFromValue([]);

                const locationId = extraFiltering.location.id || extraFiltering.location;

                const { items, paginator } = await apiItem.getAll(
                    `?available=1&inBox=0&locationId=${locationId}${paramTypeFilter}${paramTypeTablet}${filterBySerialNumber}`,
                    page,
                    limit,
                    order,
                    filter,
                    '/v2'
                );

                let newData: any[] = items.length
                    ? items.map((Item) => {
                          return {
                              id: Item.id,
                              item: Item.id,
                              type: Item.sku.skuType.type,
                              name: Item.sku.name,
                              itemId: Item.itemId,
                              itrackDeviceId: Item.itrackDeviceId,
                              version: Item.version,
                              quantity: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                              quantityTem: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                              quantityOrigin: Item.quantity || Item.quantity === 0 ? Item.quantity : 1,
                              serialNumber: Item.serialNumber
                          };
                      })
                    : [];

                if (paramTypeSKU) {
                    const {
                        data: { codeBook }
                    }: SkuModel.ApiModelSkuCodeBook = await api.getGeneral(
                        `stock/sku/type${paramTypeSKU}/assigned`,
                        undefined,
                        'v2'
                    );

                    if (codeBook) {
                        setSKUs(codeBook);
                    }
                }

                if (itemsToSendDeleted.length) {
                    itemsToSendDeleted.map((itemsToSendDeleted) => {
                        const isExistListItemsAvailable: any = newData.find(
                            (item) => itemsToSendDeleted.id === item.id
                        );
                        const isExistListItemsToSend: any = itemsToSend.find(
                            (item) => itemsToSendDeleted.id === item.id
                        );

                        if (isExistListItemsAvailable) {
                            newData = newData.map((itemAvailable) => {
                                if (
                                    itemAvailable.id === isExistListItemsAvailable.id &&
                                    itemAvailable.type === 'generic_item'
                                ) {
                                    itemAvailable.quantityTem =
                                        itemAvailable.quantity + itemsToSendDeleted.quantityOrigin;
                                    itemAvailable.quantity = itemAvailable.quantity + itemsToSendDeleted.quantityOrigin;

                                    if (isExistListItemsToSend) {
                                        itemAvailable.quantityTem -= isExistListItemsToSend.quantity;
                                        itemAvailable.quantity -= isExistListItemsToSend.quantity;
                                    }
                                }
                                return itemAvailable;
                            });
                        }
                    });
                }

                if (newData.length) {
                    newData = newData.map((itemAvailable) => {
                        if (itemAvailable.type === 'generic_item') {
                            const isExistListItemsToSend: any = itemsToSend.length
                                ? itemsToSend.find((itemToSend) => itemToSend.id === itemAvailable.id)
                                : null;
                            const isExistListItemsToSendDeleted: any = itemsToSendDeleted.find(
                                (item) => itemAvailable.id === item.id
                            );

                            if (isExistListItemsToSend && !isExistListItemsToSendDeleted) {
                                let cantRes: number = isExistListItemsToSend.quantity;

                                if (isExistListItemsToSend.isDBOriginal) {
                                    cantRes = isExistListItemsToSend.quantity - isExistListItemsToSend.quantityOrigin;
                                }

                                itemAvailable.quantityTem = itemAvailable.quantity - cantRes;
                                itemAvailable.quantity = itemAvailable.quantity - cantRes;
                            }
                        }
                        return itemAvailable;
                    });
                }

                const newDatas: any = newData.length
                    ? newData.filter((addI) => {
                          if (addI.type === 'generic_item') {
                              return addI.quantity > 0;
                          } else {
                              return true;
                          }
                      })
                    : [];

                const newDataValue = newData.length
                    ? newData.map((ItemFrom) => ({ id: ItemFrom.id, item: ItemFrom.item.id, value: null }))
                    : [];
                const listItemsFrom: any[] = newDatas.filter((item) => item.quantity > 0);

                setItemsFrom(listItemsFrom);
                setPageCount(paginator.pageCount);
                setTotal(paginator.totalCount);
                setRefresh(false);

                itemsFromValue.length
                    ? setItemsFromValue([...itemsFromValue, ...newDataValue])
                    : setItemsFromValue(items.length ? newDataValue : []);

                setResetFilter(false);
            } else {
                if (paramTypeFilter) {
                    Warning({
                        text: Trans('messages.p.to_query_by_type_you_must_first_select_the_stock_location_from')
                    });
                }
            }
            setLoadingItems(false);
        },
        [setItemsFrom]
    );

    const formatItem = (row: any): any => {
        switch (row.type) {
            case 'generic_item':
            case 'box':
                return row.name;
            case 'tablet':
            case 'sensor':
                return row.name + ' | ' + row.type;
            case 'hub':
                return (
                    row.name +
                    ' [ID:' +
                    (row.itrackDeviceId || row.itemId) +
                    '] ' +
                    (row.version ? Trans('messages.t.revision') + ' ' + row.version : Trans('messages.t.no_revision'))
                );
            default:
                return row.type;
        }
    };

    const setItemsAvailable = (row, isAdd) => {
        let listTemp = isAdd ? itemsToSend : itemsFrom;
        const listTempReverse = !isAdd ? itemsToSend : itemsFrom;
        let newList: any = [...listTemp];

        const quantityInput: HTMLElement | null = document.getElementById('cant_' + row.item);
        const quantityInputValue: number = parseInt(quantityInput?.['value']);
        const generitItem: boolean = isAdd && row.type === 'generic_item';
        const hubItem: boolean = isAdd && row.type === ItemsType.HUB;

        if (hubItem) {
            if (!row.version) {
                Warning({ text: Trans('messages.p.cant_submit_hub_without_revision') });
                quantityInput?.focus();
                throw quantityInput;
            }
        }

        if (row.isDB === 'yes') {
            itemsToSendDeleted.length
                ? setItemsToSendDeleted([...itemsToSendDeleted, row])
                : setItemsToSendDeleted([row]);
        }

        if (generitItem) {
            if (isNaN(quantityInputValue) || !quantityInputValue || quantityInputValue <= 0) {
                Warning({ text: Trans('messages.p.quantity_must_be_greater_than_0') });
                quantityInput?.focus();
                throw quantityInput;
            }

            if (quantityInputValue > parseInt(row.quantityTem)) {
                Warning({ text: Trans('messages.p.you_must_enter_an_amount_less_than') + ' ' + row.quantityTem });
                quantityInput?.focus();
                throw quantityInput;
            }

            const updateListFrom: any = itemsFromValue.map((itemsFrom) => {
                if (itemsFrom.item === row.item) {
                    itemsFrom.value = null;
                }
                return itemsFrom;
            });
            setItemsFromValue(updateListFrom);
        }

        const exists = !!listTemp.filter((obj) => obj.item === row.item).length;
        const nameItems: string = row.name ? row.name : row.type;

        if (listTemp.length) {
            const rowIsGenericItem: boolean =
                row.type === 'generic_item' && !!listTemp.filter((key) => key.item === row.item).length;

            if (rowIsGenericItem) {
                if (isAdd) {
                    listTemp = listTemp.map((key) => {
                        if (key.item === row.item) {
                            key.quantity = parseInt(key.quantity) + quantityInputValue;
                            key.isDB = 'no';
                        }
                        return key;
                    });
                    row.type === 'generic_item' ? (newList = [...listTemp]) : (newList = [...listTemp, row]);
                } else {
                    if (row.type === 'generic_item') {
                        newList = newList.map((key) => {
                            if (key.item === row.item) {
                                key.quantity = key.quantityTem + row.quantity;
                                key.quantityTem = row.quantityTem
                                    ? key.quantity === key.quantityTem
                                        ? row.quantity
                                        : key.quantity
                                    : key.quantityTem + row.quantity;
                                key.isDB = 'no';
                            }
                            return key;
                        });
                    } else {
                        newList = newList.unshift(row);
                    }
                }
            } else {
                if (row.type === 'generic_item') {
                    if (isAdd) {
                        row.quantity = quantityInputValue;
                    } else {
                        row.quantityTem = row.quantity;
                    }
                }

                if (isAdd) {
                    newList = [...listTemp, row];
                    if (exists) {
                        Warning({ text: nameItems + ' ' + Trans('messages.p.is_already_added') });
                        return false;
                    }
                } else {
                    newList = [row, ...listTemp];
                }
            }
        } else {
            if (row.type === 'generic_item') {
                if (isAdd) {
                    row.quantityTem = row.quantity;
                    row.quantity = quantityInputValue;
                } else {
                    row.quantityTem = row.quantity === 0 ? quantityInputValue : row.quantity;
                }
            }

            if (exists) {
                Warning({ text: nameItems + ' ' + Trans('messages.p.is_already_added') });
                return false;
            }

            newList = [row];
        }

        let newListItemsFilter: type.IItem[] = [];
        if (generitItem) {
            newListItemsFilter = listTempReverse.map((key) => {
                if (key.item === row.item && key.quantity > 0) {
                    key.quantityTem = key.quantityTem - quantityInputValue;
                }
                return key;
            });
            newListItemsFilter = newListItemsFilter.filter((obj) => obj.quantityTem !== 0);
        } else {
            newListItemsFilter = listTempReverse.filter((key) => key.item !== row.item);
        }
        setItemsFrom(!isAdd ? newList : newListItemsFilter);
        setItemsToSend(isAdd ? newList : newListItemsFilter);
    };

    const resetListItems = (stockLocationFromId: string, setFieldValue: any): void => {
        setFieldValue('stockLocationFrom', stockLocationFromId);

        setLocationFilter({ ...locationFilter, location: stockLocationFromId });
        if (stockLocationFromId) {
            getItemsAvailables(1, 10, {}, {}, {});
        }

        !props.resendOrder && setItemsToSend([]);
    };

    const validateQuantitySku = (e: any, row: any): void => {
        const quantityinput: HTMLElement | null = document.getElementById('cant_' + row.item);

        if (parseInt(e.target.value) > parseInt(row.quantityTem) && quantityinput) {
            Warning({ text: Trans('messages.p.the_maximum_number_is') + ' ' + row.quantityTem });
            quantityinput?.focus();
        }
    };

    const checkLocationDuplication = (
        locationFromId: OptionType | string,
        locationToId: OptionType | string,
        setFieldValue: (name: string, value: string) => void
    ): void => {
        if (locationFromId && locationToId) {
            const locationFromID: string =
                typeof locationFromId === 'object' ? `${locationFromId.id}` : `${locationFromId}`;

            const locationToID: string = typeof locationToId === 'object' ? `${locationToId.id}` : `${locationToId}`;

            if (locationFromID === locationToID) {
                Warning({
                    text: Trans('messages.p.from_and_to_equal_values')
                });
                setResetLocationTo(true);
                setFieldValue('stockLocationTo', '');
                setTimeout(() => {
                    setResetLocationTo(false);
                }, 300);
            }
        }
    };

    const checkDeliveryDuplication = async (locationFromId: string, locationToId: string, custometId: string) => {
        if (locationFromId && locationToId && custometId) {
            const items: any = await api.getUsedLocations(locationFromId, locationToId, custometId);

            setUsedLocations(items.data.codeBook);
        }
    };

    const handleSubmit = async (e: any, resetForm: any, setSubmitting: any) => {
        const withQuantity: any[] = itemsToSend.map((item) => {
            const exist: type.ItemModel[] = savedItems.filter((saveItem) => item.item === saveItem.item);
            if (exist && !!exist.length) {
                return {
                    id: exist[0].idRecord,
                    itemType: item.type,
                    quantity: item.type.toLowerCase() === 'generic_item' ? item.quantity : 1,
                    delivered: false
                };
            }
            return {
                item: item.item,
                itemType: item.type,
                quantity: item.type.toLowerCase() === 'generic_item' ? item.quantity : 1,
                delivered: false
            };
        });

        if (!itemsToSend.length) return Warning({ text: Trans('messages.p.the_list_items_to_send_cant_be_empty') });
        if (e.stockLocationFrom === e.stockLocationTo)
            return Warning({ text: Trans('messages.p.location_from_must_not_equal_location_a') });

        let orderStatus = 1;
        if (shippingOrder && shippingOrder.imported === true) {
            orderStatus = shippingOrder.shippingOrderStatus.id;
        }

        e.shippingOrderItem = withQuantity;
        e.locationFrom = e.stockLocationFrom ? e.stockLocationFrom.id : '';
        e.locationTo = e.stockLocationTo ? e.stockLocationTo.id : '';
        e.shippingOrderStatus = orderStatus;
        e.shipper = e.shipperSelect.id;
        e.itrackCustomerId = e.itrackCustomerId.id;

        delete e.type;
        delete e.quantityTem;
        delete e.stockLocationFrom;
        delete e.stockLocationTo;

        if (!e.shippedAt) {
            delete e.shippedAt;
        }

        try {
            if (!props.shippingOrderId) {
                throw props.shippingOrderId;
            }

            e.shippingOrderItem = e.shippingOrderItem.length
                ? e.shippingOrderItem.map((addI) => {
                      return addI.id
                          ? {
                                id: addI.id,
                                quantity: addI.quantity
                            }
                          : {
                                item: addI.item,
                                quantity: addI.quantity
                            };
                  })
                : [];

            const newItems: { item: number; quantity: number }[] = itemsToSend.length
                ? itemsToSend.map((item) => ({
                      item: item.id,
                      quantity: item.quantity
                  }))
                : [];

            const newData: ItemsResendOrder | null = shippingOrder
                ? {
                      trackingId: e.trackingId,
                      trackingEnabled: e.trackingEnabled,
                      saleOrderId: e.saleOrderId,
                      shipmentAcceptanceEmail: e.shipmentAcceptanceEmail,
                      locationFrom: e.locationFrom,
                      locationTo: e.locationTo,
                      shippingOrderStatus: 1,
                      shippingOrderItem: [...newItems],
                      parentShippingOrder: shippingOrder.id,
                      itrackCustomerId: e.itrackCustomerId?.id || e.itrackCustomerId || null
                  }
                : null;

            const { data }: Model = props.resendOrder
                ? await api.create(newData)
                : await api.patch(parseInt(props.shippingOrderId.toString()), e);

            if (!data) {
                throw data;
            }

            resetForm({});

            Success({
                text: props.resendOrder
                    ? Trans('messages.t.shipping_order_resend_successfully') + ` [ID ${data && data.shippingOrder.id}]`
                    : Trans('messages.t.shipping_order_successfully_updated') + ` [ID ${data.shippingOrder.id}]`
            });
            setItemsFrom([]);
            setItemsToSend([]);
            setSubmitting(false);
            props.closeModal();
            props.setRefresh(true);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const formatDataItems = (dataItems: any, itemsAvailable = true) => {
        let newList: any = [];
        if (dataItems.length) {
            if (itemsAvailable) {
                newList = dataItems;
            } else {
                newList = dataItems.map((i) => ({
                    id: i.item.id,
                    idRecord: i.id,
                    item: i.item.id,
                    itemId: i.item.itemId,
                    itrackDeviceId: i.item.itrackDeviceId,
                    version: i.item.version,
                    serialNumber: i.item ? i.item.serialNumber : '---',
                    type: i.item.sku.skuType.type,
                    quantity: i.quantity,
                    quantityTem: i.quantity,
                    quantityOrigin: i.quantity,
                    name:
                        i.item.sku.skuType.type === 'generic_item' ||
                        i.item.sku.skuType.type === 'hub' ||
                        i.item.sku.skuType.type === 'sensor'
                            ? i.item.sku.name
                            : i.item.sku.skuType.type,
                    id_box: i.item.sku.skuType.type === 'box' ? i.item.id : null,
                    isDB: 'yes',
                    isDBOriginal: 'yes'
                }));
            }
        }
        return newList;
    };

    const setValueFrom = (row: any, resetValue = false) => {
        const quantityinput: HTMLElement | null = document.getElementById('cant_' + row.item);

        itemsFromValue.map((itemsFrom) => {
            if (itemsFrom.item === row.item) itemsFrom.value = resetValue ? null : quantityinput?.['value'];
            return itemsFrom;
        });
    };

    const currentValueFrom = (row: any): number => {
        const valueItems: any =
            itemsFromValue && !!itemsFromValue.length
                ? itemsFromValue.find((item) => item.item === row.item)
                : { value: null };
        return valueItems ? valueItems.value : null;
    };

    const elementSRowEdit = (row) => {
        if (row.type === 'generic_item') {
            return (
                <>
                    <input
                        type='number'
                        style={{ marginLeft: '3px', width: '40%' }}
                        title={Trans('messages.p.the_quantity_is_indicated_in_the_SKU_type_item')}
                        disabled={row.type === 'generic_item' ? false : true}
                        key={`quantity_${row.item}`}
                        id={`cant_${row.item}`}
                        defaultValue={currentValueFrom(row)}
                        onChange={(e) => {
                            validateQuantitySku(e, row);
                            setValueFrom(row);
                        }}
                    />
                    Max: {row.quantityTem}
                </>
            );
        } else {
            return <> {notData} </>;
        }
    };

    const getCustomers = async (locationToId: any): Promise<void> => {
        if (!locationToId) {
            setCustomers([]);
            return;
        }

        const customers: ModelCustomerItrack[] = await apiCustomers.getCustomersCodeBook({
            itrackStockPrimaryDeliveryLocationId: locationToId
        });
        setCustomers(customers);
    };

    const addCustomersToList = (
        customers: ModelCustomerItrack[],
        shippingOrder: type.IShippingOrder | null
    ): ModelCustomerItrack[] => {
        if (customers.length <= 0) {
            return [];
        }

        if (shippingOrder && shippingOrder.itrackCustomerId) {
            const isExists: boolean =
                customers.filter((customer) => (customer?.id === shippingOrder.itrackCustomerId?.id)).length > 0;
            const newList = !isExists ? [...customers, shippingOrder.itrackCustomerId] : customers;
            return newList;
        }

        return customers;
    };

    const ElementSRowEditMemo = React.memo((props: any) => elementSRowEdit(props.row));
    ElementSRowEditMemo.displayName = 'ElementSRowEditOrderMemo';

    const columnsAvailableItems: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 15
        },
        {
            Header: Trans('messages.t.item'),
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            width: 70,
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) =>
                original.type === ItemsType.BOX ? (
                    <TitleItemWithIcon>
                        {`${original.name} `}
                        <IconButton
                            size='small'
                            className='info'
                            style={{ minWidth: 'auto' }}
                            title={Trans('messages.t.items')}
                            id={`showItems${original.id}`}
                            onClick={() => getItems(original.id)}
                        >
                            <Search />
                        </IconButton>
                    </TitleItemWithIcon>
                ) : original.type === ItemsType.HUB ? (
                    <TextEllipsis title={formatItem(original)} className={!original.version ? 'danger' : ''}>
                        {formatItem(original)}{' '}
                    </TextEllipsis>
                ) : (
                    <TextEllipsis title={formatItem(original)}> {formatItem(original)} </TextEllipsis>
                )
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            width: 40,
            minWidth: 40,
            canFilters: true,
            Cell: ({ row }: CellProps) => (row.original.type === 'box' ? notData : row.original.serialNumber)
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            width: 50,
            minWidth: 50,
            canFilters: true,
            Cell: ({ row }: CellProps) => <ElementSRowEditMemo row={row.original} />
        },
        {
            Header: Trans('messages.t.action'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'action',
            width: 20,
            minWidth: 20,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <>
                    <IconButton
                        size='small'
                        className={row.original.type === ItemsType.HUB && !row.original.version ? 'default' : 'success'}
                        style={{ minWidth: 'auto' }}
                        title={Trans('messages.t.add')}
                        id={`btn_edit_${row.original.id}`}
                        onClick={() => setItemsAvailable(row.original, true)}
                    >
                        <ArrowForwardOutlined />
                    </IconButton>
                </>
            )
        }
    ];

    const columnsItemsToSend: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 15,
            Cell: ({ row }: CellProps) => (row.original.item ? row.original.item : row.original.id),
            Export: ({ row }: CellProps) => (row.original.item ? row.original.item : row.original.id)
        },
        {
            Header: Trans('messages.t.item'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            width: 60,
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) =>
                original.type === ItemsType.BOX ? (
                    <TitleItemWithIcon>
                        {`${original.name} `}
                        <IconButton
                            size='small'
                            className='info'
                            style={{ minWidth: 'auto' }}
                            title={Trans('messages.t.items')}
                            id={`showItems${original.id}`}
                            onClick={() => getItems(original.id)}
                        >
                            <Search />
                        </IconButton>
                    </TitleItemWithIcon>
                ) : original.type === ItemsType.HUB ? (
                    <TextEllipsis title={formatItem(original)} className={!original.version ? 'danger' : ''}>
                        {formatItem(original)}{' '}
                    </TextEllipsis>
                ) : (
                    <TextEllipsis title={formatItem(original)}> {formatItem(original)} </TextEllipsis>
                )
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40,
            minWidth: 40,
            Cell: ({ row }: CellProps) => (row.original.type === 'box' ? notData : row.original.serialNumber)
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 30,
            minWidth: 30,
            Cell: ({ row }: CellProps) =>
                row.original.type.toLowerCase() === 'generic_item' ? row.original.quantity : notData
        },
        {
            Header: Trans('messages.t.action'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'action',
            width: 30,
            minWidth: 30,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <>
                    <IconButton
                        size='small'
                        style={{ minWidth: 'auto', color: '#ef4430' }}
                        title={Trans('messages.t.remove')}
                        id={`btn_edit_${row.original.id}`}
                        onClick={() => setItemsAvailable(row.original, false)}
                        color='secondary'
                    >
                        <Delete />
                    </IconButton>
                </>
            )
        }
    ];

    useEffect(() => {
        userState?.id && getResourcesForEdit();
    }, [getResourcesForEdit, userState]);

    useEffect(() => {
        return () => {
            setStatusHistory([]);
        }
    }, [])

    return (
        <>
            <BoxItems
                BoxId={boxId}
                modalItems={modalItems}
                setModalItems={setModalItems}
                setBoxId={setBoxId}
                typeModal='drawer'
            />
            <view.EditShippingOrderContent
                data-testid='EditShippingOrder-testid'
                shippingOrder={shippingOrder}
                openModal={() => false}
                handleSubmit={handleSubmit}
                closeModal={handleClose}
                open={props.open}
                formschema={Formschema}
                columns={[]}
                valuesInitForm={valuesInitForm}
                itemsFrom={itemsFrom}
                itemsToSend={itemsToSend}
                resetListItems={resetListItems}
                isLoading={loadingItems}
                shippingOrderId={props.shippingOrderId}
                loading={loading}
                columnsItemsToSend={columnsItemsToSend}
                columnsAvailableItems={columnsAvailableItems}
                SKUs={SKUs}
                resetFilter={resetFilter}
                fetchData={getItemsAvailables}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                serialNumber={serialNumber}
                setSerialNumber={setSerialNumber}
                extraFiltering={locationFilter}
                setLocationFilter={setLocationFilter}
                fromUserTimezoneToTimezone={fromUserTimezoneToTimezone}
                getDateMask={getDateMask}
                resetLocationTo={resetLocationTo}
                checkLocationDuplication={checkLocationDuplication}
                resendOrder={props.resendOrder}
                checkDeliveryDuplication={checkDeliveryDuplication}
                usedLocations={usedLocations}
                customers={addCustomersToList(customers, shippingOrder)}
                getCustomers={getCustomers}
                columnsChangeStatus={columnsChangeStatus}
                disabledCustomer={disabledCustomer}
                setDisabledCustomer={setDisabledCustomer}
            />
        </>
    );
};

export default Wrapper(EditShippingOrder);
