import React from 'react';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { SimpleSelectAsyncPaginate } from '../FormElements';
import { LocationDropDownViewProps } from './LocationDropDown.type';
import { Checkbox, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LocationDropDownFilter } from './LocationDropDown.style';
import * as style from '../OrderList/OrderList.style';

// import module

export const LocationDropDownContent: React.FC<LocationDropDownViewProps> = ({
    value,
    error,
    locationList,
    onChange,
    required,
    name,
    label,
    loadMoreLocation,
    lg,
    md,
    sm,
    setShowFilter,
    showFilter,
    locationType,
    selectedLocationType,
    setSelectedLocationType
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const classes = style.useStyles();

    return (
        <Grid lg={lg} md={md} sm={sm} xs={12} className={'textFieldFormik'}>
            <Grid container>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                {!showFilter && <SimpleSelectAsyncPaginate
                        name={name}
                        label={label}
                        value={value}
                        error={error}
                        options={locationList}
                        onChange={onChange}
                        required={required}
                        loadOptions={loadMoreLocation}
                        textRequired={Trans('messages.p.this_field_is_required')}
                        lg={lg}
                        md={md}
                        sm={sm}
                    />}
                {showFilter && (<FormControl sx={{ m: 0, width: '100%' }}>
                        <InputLabel
                            shrink={true}
                            variant='outlined'
                            style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                        >
                            {'Filter'}
                        </InputLabel>
                        <Select
                            className={classes.inputFilterColumn}
                            multiple
                            value={selectedLocationType}
                            onChange={(e) => {
                                if(e.target.value){
                                    setSelectedLocationType(e.target.value as number[]);
                                }
                            }}
                            input={<OutlinedInput label='Tag' />}
                            renderValue={(selected: any) =>{
                                return locationType.filter((type) => selected.includes(type.id)).map((status) => status.name).join(', ')
                            }
                            }
                        >
                            {locationType.map((status) => (
                                <MenuItem key={status.id} value={status.id} style={{ padding: '0px' }}>
                                    <Checkbox
                                        checked={
                                            selectedLocationType.filter((type) => type === status.id).length > 0
                                        }
                                    />
                                    <ListItemText primary={status.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>)}
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                    <LocationDropDownFilter>
                        {<IconButton aria-label='filter-location' size='small' onClick={() => {
                            setShowFilter((current) => !current);
                        }}>
                            <FilterListIcon fontSize="small" />
                        </IconButton>}
                    </LocationDropDownFilter>
                </Grid>
            </Grid>
        </Grid>
    );
};
